@import url("https://fonts.googleapis.com/css?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap");

$primaryColor: #000000; // #352793;
$secondaryColor: #082032; // #1D4AD1;
$primaryFont: "Paytone One", sans-serif;
$secondaryFont: "Nunito", sans-serif;

.brand-logo {
  margin-top: 10px;
  height: 60px;
  /* max-width: 75px; */
}

.header-transparent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  transform: translateY(0);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  &.scrolled {
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px); /* Safari support */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    
    /* Ensure smooth transition */
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    
    /* Improve mobile compatibility */
    @supports not (backdrop-filter: blur(8px)) {
      background: rgba(0, 0, 0, 0.95);
    }
    
    .navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    
    .brand-logo {
      height: 50px;
      margin-top: 5px;
      transition: all 0.3s ease-in-out;
    }
    
    .nav-link {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    
    &.scroll-down {
      transform: translateY(-100%);
    }
    
    &.scroll-up {
      transform: translateY(0);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    }
  }
  
  .navbar {
    transition: all 0.3s ease-in-out;
  }

  .navbar-brand {
    color: white;
    /* transform: rotate(-90deg); */
    font-weight: 900;
  }

  span i {
    color: white;
    padding-left: 50%;
  }

  .navbar-toggler {
    display: block;
    border: none;
    color: white;
    padding: 0.75rem;
    margin-right: 0.5rem;
    
    &:focus {
      outline: 2px solid rgba(255, 255, 255, 0.2);
      box-shadow: none;
    }
  }

  .navbar-collapse.show,
  .collapsing {
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    margin: 0 -1rem;
    padding: 1rem;
  }

  nav.navbar ul li a.nav-link {
    color: white;
    padding: 1rem;
    border-bottom: 1px solid $secondaryColor;
  }
}

/* for mobile phones (portrait) */
@media (min-width: 320px) and (max-width: 480px) {
  .header-transparent {
    .navbar {
      padding-top: 5%;

      .navbar-brand {
        margin-left: 3%;
      }
    }

    .navbar-collapse.show,
    .collapsing {
      background: $primaryColor;
    }

    nav.navbar ul li a.nav-link {
      color: white;
      padding: 1rem;
      border-bottom: 1px solid $secondaryColor;
    }
  }
}

/* for desktop */
@media (min-width: 992px) {
  nav.navbar ul li a.nav-link {
    padding-right: 30px !important;
    padding-left: 30px !important;
    padding-top: 40px !important;
    color: white;
    font-family: $secondaryFont !important;
    font-weight: bold !important;
    border-bottom: none !important;
  }

  #navbarSupportedContent > ul > li > a:hover {
    color: rgb(240, 165, 0);
  }

  .header-transparent {
    .navbar-toggler {
      display: none;
    }
    
    &.scrolled {
      nav.navbar ul li a.nav-link {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
      }
    }
  }
}

/* for mobile phones (landscape) */

/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .header-transparent {
    .navbar {
      margin-top: 13px;
    }

    .navbar-collapse.show,
    .collapsing {
      background: $primaryColor;
    }

    nav.navbar ul li a.nav-link {
      color: white;
      padding: 1rem;
      border-bottom: 1px solid $secondaryColor;
    }
  }
}

/* for mobile phones (landscape) */
/* iphone 6/7/8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .header-transparent {
    .navbar {
      margin-top: 13px;
    }

    nav.navbar.fixed-top {
      position: relative;
    }

    .navbar-collapse.show,
    .collapsing {
      background: $primaryColor;
    }

    nav.navbar ul li a.nav-link {
      color: white;
      padding: 1rem;
      border-bottom: 1px solid $secondaryColor;
    }
  }
}

/* tablets (portrait) */
@media (min-width: 768px) and (max-width: 1024px) {
  .header-transparent {
    .navbar {
      margin-top: 13px;
    }

    nav.navbar.fixed-top {
      position: relative;
    }

    .navbar-collapse.show,
    .collapsing {
      background: $primaryColor;
    }

    nav.navbar ul li a.nav-link {
      color: white;
      padding: 1rem;
      border-bottom: 1px solid $secondaryColor;
    }
  }
}

/* tablets (landscape) */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  nav.navbar ul li a.nav-link {
    border-bottom: none;
  }
}

/* iPad Pro (Portrait and Landscape) */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  nav.navbar ul li a.nav-link {
    border-bottom: none !important;
  }
}

// Mobile (up to 768px)
@media (max-width: 767px) {
  .header-transparent {
    .navbar {
      padding: 0.5rem 1rem;
    }
    
    .navbar-collapse {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 1000;
    }
  }
}

// Tablet and up (768px+)
@media (min-width: 768px) {
  .header-transparent {
    nav.navbar ul li a.nav-link {
      border-bottom: none;
      padding: 1.5rem 1rem;
    }
  }
}

// Desktop (992px+)
@media (min-width: 992px) {
  .header-transparent {
    .navbar-toggler {
      display: none;
    }
    
    nav.navbar ul li a.nav-link {
      padding: 2rem 1.5rem;
      font-weight: 500;
    }
  }
}
