@import url('https://fonts.googleapis.com/css?family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Courgette&display=swap');

.reactive-btn-wrapper,
.reactive-btn {
  --reactive-button-min-width: 100px !important;
  --reactive-button-min-height: 35px !important;
  --reactive-button-font-size: 14px !important;
  --reactive-button-font-weight: 400 !important;
  --reactive-button-border-radius: 0px !important;
  --reactive-button-text-color: #ffffff !important;
  --reactive-progress-color: rgba(0, 0, 0, 0.15) !important;

  --reactive-button-primary-color: rgba(88, 103, 221, 1) !important;
  --reactive-button-secondary-color: rgba(13, 105, 134, 1) !important;
  --reactive-button-dark-color: rgba(7, 72, 91, 1) !important;
  --reactive-button-light-color: rgba(183, 186, 191, 1) !important;
  --reactive-button-green-color: rgba(37, 162, 51, 1) !important;
  --reactive-button-red-color: rgba(244, 81, 108, 1) !important;
  --reactive-button-yellow-color: rgba(255, 193, 7, 1) !important;
  --reactive-button-teal-color: rgba(0, 181, 173, 1) !important;
  --reactive-button-violet-color: rgba(100, 53, 201, 1) !important;
  --reactive-button-blue-color: rgba(66, 153, 225, 1) !important;

  --reactive-progress-outline-primary-color: rgba(88, 103, 221, 0.3) !important;
  --reactive-progress-outline-secondary-color: rgba(108, 117, 125, 0.3) !important;
  --reactive-progress-outline-dark-color: rgba(66, 78, 106, 0.3) !important;
  --reactive-progress-outline-light-color: rgba(214, 212, 212, 0.3) !important;
  --reactive-progress-outline-green-color: rgba(37, 162, 51, 0.3) !important;
  --reactive-progress-outline-red-color: rgba(244, 81, 108, 0.3) !important;
  --reactive-progress-outline-yellow-color: rgba(255, 193, 7, 0.3) !important;
  --reactive-progress-outline-teal-color: rgba(0, 181, 173, 0.3) !important;
  --reactive-progress-outline-violet-color: rgba(100, 53, 201, 0.3) !important;
  --reactive-progress-outline-blue-color: rgba(66, 153, 225, 0.3) !important;
}

/* colors */
$primaryColor: #334756; // #352793;
$secondaryColor: #082032; // #1D4AD1;
$thirdColor: #334756; // #F0A500; // #130f40;
$forthColor: #000000; // #0c2461;

/* fonts */
$primaryFont: 'Paytone One',
sans-serif;
$secondaryFont: 'Nunito', sans-serif;
$thirdFont: 'Courgette', cursive;

/* sizes */
$titleFontSize: 100px;



.App {
    text-align: center;
}

/* global classes */

.title-font {
    font-family: $primaryFont;
    line-height: 130%;
}

.title-font-2 {
    font-family: $secondaryFont;
    line-height: 130%;
}

.title-font-3 {
    font-family: $thirdFont;
    line-height: 130%;
}


.title-font-size {
    font-size: $titleFontSize;
}

.section-title {
    padding-top: 7%;
}

a {
  color: #F0A500;
}

a:hover {
    color: rgba(183, 186, 191, 1);
    text-decoration: none;
  }

/* --------------------- Banner --------------------- */

.banner {
    position: relative;
    color: white;
    margin-top: 0px;
}

.banner:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-image: url('./img/banner1.jpg');
  filter: blur(4px);
  -webkit-filter: blur(4px);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: stretch;
}

.banner-image-mobile {
    display: none;
}

.banner-image {
    width: 90%;
    border-radius: 10%;
}

.banner-text {
    margin-bottom: 3px;
}

.banner-text h1 {
    font-size: 62px;
}

.banner-text .text-yellow {
    color: #7ced03;
}

.banner-text .text-customer {
    color: rgb(240, 165, 0);
}

/*----------------------About us area--------------------------*/

.about-us-area {
    background: $thirdColor no-repeat;
    /* background-image: url('./img/shape2.png'); */
    background-position: center;
    background-size: 400px;
    padding: 5%;

    .about-us-text-area {
        padding: 3em;
        background: $forthColor;
        -webkit-box-shadow: 0px 0px 46px -17px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 46px -17px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 46px -17px rgba(0,0,0,0.75);

        h2 {
            font-weight: 900;
        }
    }

}

/*---------------------- portfolio area --------------------------*/

.portfolio {
    padding: 7%;

    .portfolio-thumb {
        margin: 0 auto;
        margin-bottom: 9%;

        /* width: 100%;
        height: 100%; */
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;


        img {
            width: 100%;
            transition: transform .25s, visibility .25s ease-in;
        }
        :hover img {
            transform: scale(1.5);
        }
        .image-container {
            .hover-content {
                width: 100% !important;
                height: 100% !important;
                background: $primaryColor;
            }
            .title {
                color: white;
                opacity: 1;
                margin-top: 25%;
                font-weight: 900;
                font-size: 30px;
                font-family: $secondaryFont;
            }
            .hover-content.fadeIn {
                opacity: 0.9;
            }
        }
    }
}


/*---------------------- lead area --------------------------*/

.lead {
    background: $thirdColor;

    h1 {
        font-size: $titleFontSize;
    }

    button {
        border-radius: 7px 7px 7px 7px;
        -moz-border-radius: 7px 7px 7px 7px;
        -webkit-border-radius: 7px 7px 7px 7px;
        border: 0px solid #000000;
        height: 50px;
        width: 50%;
        background: $primaryColor;
        font-family: $secondaryFont;
        font-weight: 900;
        font-size: 100%;

        :hover {
            text-decoration: none;
        }

        a {
            color: #ffff;
        }
    }
}

/*---------------------- footer area --------------------------*/
footer {
    background: $thirdColor;
    bottom: 0;
    width: 100%;
}

/*---------------------------------------------------------------*/
/*---------------------- contact page ---------------------------*/
/*---------------------------------------------------------------*/

.contact {

    p {
        font-size: 1.6rem;
    }

    .contact-form {
        padding-bottom: 10%;

        form-group:focus {
            outline: none;
        }

        input, textarea {
            background: transparent;
            font-family: $secondaryFont;
            border-bottom: 1px solid white;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
            padding: 4%;
            color: white;
        }

        input::placeholder, textarea::placeholder {
            color: white;
            opacity: 0.5;
        }

        input:focus, textarea:focus {
            outline: none;
            box-shadow: none;
        }

        button {
            border-radius: 7px 7px 7px 7px;
            -moz-border-radius: 7px 7px 7px 7px;
            -webkit-border-radius: 7px 7px 7px 7px;
            border: 0px solid #000000;
            height: 50px;
            width: 50%;
            background: $secondaryColor;
            font-family: $secondaryFont;
            color: white;
            font-weight: 900;
            font-size: 100%;

            :hover {
                text-decoration: none;
            }

            :focus {
                outline: white;
                box-shadow: white;
            }
        }
    }
}

/*---------------------------------------------------------------*/
/*---------------------- services page ---------------------------*/
/*---------------------------------------------------------------*/

.services {
  // padding: 0px 0px 40px 0px;

    .services-title {
        padding-bottom: 5%;
    }
    .title-text {
        font-size: 1.6rem;
    }

    .service-name {
        font-weight: 900;
        font-size: 25px;
    }

    .service-boxes {
        padding: 10%;
        .service-icon {
            position: absolute;
            right: 90%;
        }

        img {
            width: 90px;
        }

        .service-info-title {
            height: 25px;
            margin-bottom: 5%;
        }

        .service-description {
            font-size: 20px;
        }
    }
}

/*---------------------------------------------------------------*/
    /*--------------- portfolio detail page --------------*/
/*---------------------------------------------------------------*/

.detail {
    .detail-title {
      padding: 0;
      padding-top: 20%;
      padding-bottom: 5%;
      h1 {
          font-size: -webkit-xxx-large;
      }
    }
    .detail-image {
        width: 100%;
    }

    .detail-info {
        .work-title {
            font-weight: 900;
        }

        .description {
            line-height: 1.6rem;
            font-size: larger;
        }

        hr {
            border-color:white; border-style:solid
        }
    }
}

/*---------------------------------------------------------------*/
    /*--------------- team page --------------*/
/*---------------------------------------------------------------*/

.team {

    margin-bottom: 10%;

    .portfolio-thumb {
        margin: 0 auto;
        margin-bottom: 5%;

        /* width: 100%;
        height: 100%; */
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;

        img {
            width: 100%;
            transition: transform .25s, visibility .25s ease-in;
        }
        :hover img {
            transform: scale(1.5);
        }

        .image-container {
            .hover-content {
                width: 100% !important;
                height: 100% !important;
                background: $primaryColor;
            }
            .title {
                color: white;
                opacity: 1;
                margin-top: 30%;
                font-weight: 900;
                font-size: 30px;
                font-family: $secondaryFont;
            }
            .hover-content.fadeIn {
                opacity: 0.9;
            }
        }
    }

    p {
        font-size: 1.6rem;
    }

    .person-info {
        visibility: visible;
        display: none;
    }
}
/* for mobile phones (portrait) */
@media (min-width: 320px) and (max-width: 480px) {

    /* global styles */
    .section-title {
        padding-top: 0%;
    }
    /* global styles end */

    .title-font-size {
        font-size: -webkit-xxx-large;
    }

    .banner {
        padding: 0px 0 180px;
    }

    .banner-image-desktop {
        display: none;
    }

    .banner-image-mobile {
        display: block;
    }

    .banner-text {

        @media screen and (max-width: 25em) {
            margin-top: 3rem!important;
        }
    }

    .banner-text h1 {
        font-size: 48px;
        margin-bottom: 2%;
        padding: 4%;
    }

    .banner-image {
        width: 90%;
    }

    /*----------------------About us area--------------------------*/

    .about-us-area {
        background-size: 400px;
        .about-us-text-area {
            h2 {
                font-size: 25px;
            }
        }
    }

    /*----------------------portfolio area--------------------------*/
    .portfolio {
        .portfolio-title {
            padding: 0;
            padding-top: 20%;

            h1 {
                font-size: -webkit-xxx-large;
            }
        }
    }

    /*----------------------lead area--------------------------*/
    .lead {
        h1 {
            font-size: -webkit-xxx-large;
        }
    }
    /*----------------------contact page--------------------------*/

    .contact {
        p {
            font-size: 1.6rem;
        }
    }
    /*----------------------services page--------------------------*/

    .services {
        .services-title {
            padding: 0;
            padding-top: 30%;

            h1 {
                font-size: -webkit-xxx-large;
            }
        }
        .service-boxes {
            .service-icon {
                position: unset;
            }

            .service-info-title {
                height: 50px;
                margin-bottom: 5%;

                .service-name {
                    font-size: 20px;
                }
            }

            .service-description {
                text-align: center !important;
                font-size: 15px;
            }

        }
        .title-text {
            font-size: 1.6rem;
        }
    }
    /*----------------------team page--------------------------*/

    .team {

        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
}


/* for desktop */
@media (min-width: 992px) {
    .banner {

        padding: 120px 0 70px;

        .banner-text {
            padding-top: 39%;
        }
    }


}

/* for mobile phones (landscape) */
/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation : landscape) {

    .banner {
        padding: 0px 0px 100px !important;

        .banner-text {
            padding-top: 10% !important;

            h1 {
                font-size: 48px;
                text-align: center;
            }
        }

        .banner-image {
            width: 23% !important;
            margin-top: 30px;
        }
    }

    .portfolio {
        .portfolio-title {
            padding: 0;
        }
    }

    .services {
        .services-title {
            padding-bottom: 0;
        }
        .service-boxes {
            .service-icon {
                position: unset !important;
            }

            .service-description {
                text-align: center !important;
            }
        }
    }

    .team {
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }

    /* global styles */
    .section-title {
        padding-top: 0%;
    }

  }

  /* for mobile phones (landscape) */
  /* iphone 6/7/8 */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation : landscape) {

    .banner {
        padding: 0px !important;
        .banner-text {
            padding-top: 10%;

            h1 {
                font-size: 48px;
                text-align: center;
            }
        }
        .banner-image {
        width: 50%;
        margin-top: 30px;
        }
    }

    .portfolio {
        .portfolio-title {
            padding: 0;
        }
    }

    .team {

        padding-top: 0%;

        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
  }

  /* pixel 2 xl (landscape) */
  @media only screen and (min-width: 411px) and (max-width: 767px) {
    .team {
        padding-top: 0%;

        .team-title {

            h1 {
                font-size: 75px;
            }
        }
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
  }

  /* pixel 2 */
  @media only screen and (min-width: 411px) and (max-width: 767px) {
    .team {
        padding-top: 0%;

        .team-title {
            margin-top: 0rem;
            padding-top: 10px;

            h1 {
                font-size: 75px;
            }
        }
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
  }
  /* tablets (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {

    .banner {
        padding: 0px 0px 100px;
        .banner-text {
            padding-top: 10%;

            h1 {
                font-size: 48px;
                text-align: center;
            }
        }
        .banner-image {
            width: 50%;
            margin-top: 30px;
            }
    }
    .contact {
        padding-bottom: 10%;

        .contact-form {
            padding-bottom: 30%;
        }
    }

    .services {
        .service-boxes {
            .service-icon {
                position: unset !important;
            }

            .service-description {
                text-align: center !important;
            }
        }
    }

    .team {
        padding-top: 0%;

        .team-title {
            h1 {
                font-size: 75px;
            }
        }
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }

  }

  /* tablets (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .banner {
        padding: 10%;

        .banner-text {
            padding-top: 20%;
        }

        .banner-image {
            width: 80%;
        }
    }

    .team {
        padding-top: 10%;

        .team-title {
            h1 {
                font-size: 75px;
            }
        }
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }

  }

    /* iPad Pro (Portrait and Landscape) */
    @media only screen
    and (min-width: 1024px)
    and (max-height: 1366px)
    and (-webkit-min-device-pixel-ratio: 1.5) {

        .banner {
            padding: 10%;

            .banner-text {
                padding-top: 20%;
            }

            .banner-image {
                width: 80%;
            }
        }

        .team {
            padding-top: 10%;


            .person-info {
                visibility: visible;
                display: inline;

                .person-position {
                    font-size: 15px;
                    margin-bottom: 15%;
                }
            }
        }
    }
