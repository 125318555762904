body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  color: #fff;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  
  @media (min-width: 640px) {
    padding: 0 1.5rem;
  }
  
  @media (min-width: 1024px) {
    padding: 0 2rem;
  }
}

::selection {
  background: rgba(59, 130, 246, 0.3);
  color: #fff;
}

:focus {
  outline: 2px solid rgba(59, 130, 246, 0.5);
  outline-offset: 2px;
}

img {
  max-width: 100%;
  height: auto;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a, button {
  touch-action: manipulation;
}

@media (prefers-reduced-motion: no-preference) {
  * {
    scroll-behavior: smooth;
  }
}

main {
  position: relative;
  z-index: 1;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  
  main {
    flex: 1;
  }
}
